/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:1a5a994d-29b4-4c33-bc86-b193b038e5e1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VpD8T9ku7",
    "aws_user_pools_web_client_id": "65s9hk5a8refb3vrbe4084uhhb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "rawData",
            "endpoint": "https://fa7j5qel3h.execute-api.us-east-1.amazonaws.com/devl",
            "region": "us-east-1"
        },
        {
            "name": "stripe",
            "endpoint": "https://0n6th8efv5.execute-api.us-east-1.amazonaws.com/devl",
            "region": "us-east-1"
        },
        {
            "name": "reportData",
            "endpoint": "https://10sn0tpcu4.execute-api.us-east-1.amazonaws.com/devl",
            "region": "us-east-1"
        },
        {
            "name": "devices",
            "endpoint": "https://gpqaiq8533.execute-api.us-east-1.amazonaws.com/devl",
            "region": "us-east-1"
        },
        {
            "name": "dailymed",
            "endpoint": "https://ffwg9mwdd0.execute-api.us-east-1.amazonaws.com/devl",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://yahlt6rdmzh4viry36xelqlptu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
